.App {
  font-family: 'Quicksand', sans-serif;
}

.container {
  margin: 70px;
}

.service-card {
  transition: transform 0.3s ease;
}

/* .service-card:hover {
  transform: scale(1.1);
} */

a {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

.value-title:hover .heading-color-change {
  color: #ff6b6b;
}

.dev-card:hover .dev-card-header {
  color: #ff6b6b;
}

.header-text1 {
  opacity: 0;
  transform: translateX(-1000px);
  transition: opacity 2s ease-in-out, transform 2.5s ease-in-out;
}

.header-text1.loaded {
  opacity: 1;
  transform: translateY(0);
}

.header-text2 {
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 2s ease-in-out, transform 2.5s ease-in-out;
}

.header-text2.loaded {
  opacity: 1;
  transform: translateY(0);
}

.underline {
  position: relative;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: 1px; /* Adjust this value to control the space between text and underline */
  left: 0;
  right: 0;
  height: 2px;
  background-color: white; /* Adjust the color of the underline */
}

/* App.css */

.tag-container {
  display: flex;
  flex-wrap: wrap;
  animation: moveTags 5s linear infinite;
}

@keyframes moveTags {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Add other CSS styling as needed */
